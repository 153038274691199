import "./Footer.css";

import "../pages/Home.css";
function Footer() {
  return (
    <div className="footer">
      <div className="footer__container">
        <h3 className="footer__credits credits__cutzu">
        </h3>
        <div className="footer__copyright">
          <h3 className="footer__credits-f_displayFlex">
            2023 &copy; TT Software Services
          </h3>
          <ul className="footer__credits--list">
            <li className="footer__credits--item">
              
            </li>
            <li className="footer__credits--item"></li>
          </ul>
          <ul className="footer__credits--list">
            <li className="footer__credits--item"> TEL: +31 6 15 43 18 93</li>
            <li className="footer__credits--item">KVK: 86300091</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
